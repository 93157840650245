import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  Footer,
  CopyrightLabel,
  SiteMap,
  SocialMedia,
  DynamicScript
} from "@fishrmn/fishrmn-components";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import "semantic-ui-less/semantic.less";
import "./layout.css";

import MessageDisplayWrapper from "./MessageDisplayWrapper";
import Navigation from "./Navigation";

export default (props) => {
  const { children } = props;
  return (
    <StaticQuery
      query={graphql`
        query {
          allFisherman {
            edges {
              node {
                businessName
                logo
                socialMedia {
                  link
                  type
                }
              }
            }
          }
          allFishermanImages {
            edges {
              node {
                heroImages {
                  url
                }
                galleryImages {
                  url
                }
              }
            }
          }
        }
      `}
      render={(queryData) => {
        const businessData = queryData.allFisherman.edges[0].node;
        const imageData = queryData.allFishermanImages.edges[0].node;
        const data = { ...businessData, ...imageData };

        
        return (
          <div className="site-container">
            <div>
              {/* <MessageDisplayWrapper /> */}

              <Navigation
                links={[
                  { pageTitle: "Home", slug: "/", internal: true },
                  {
                    pageTitle: "Contact Us",
                    slug: "/contact/",
                    internal: true,
                  },
                  {
                    pageTitle: "Our Story",
                    slug: "/our-story/",
                    internal: true,
                  },
                  {
                    pageTitle: "Catering",
                    slug: "https://order.eggnbird.com",
                    internal: false,
                  },
                  {
                    pageTitle: "Gift Cards",
                    slug: "/giftcards/",
                    internal: true,
                  },
                  {
                    pageTitle: "Locations",
                    slug: "/locations/",
                    internal: true,
                  },
                  { pageTitle: "Careers", slug: "/careers/", internal: true },
                  {
                    pageTitle: "Franchise",
                    slug: "/franchise/",
                    internal: true,
                  },
                  {
                    pageTitle: "Earn Rewards",
                    slug: "/rewards/",
                    internal: true,
                    className:'join-now'
                  },
                  {
                    pageTitle: "Order Online",
                    slug: "https://order.eggnbird.com/",
                    internal: false,
                  },
                  // {
                  //   slug: "",
                  //   pageTitle: "",
                  //   internal: false,
                  //   className: "item dropdown",
                  //   dropdown: {
                  //     title: "Order Online",
                  //     options: [
                  //       {
                  //         pageTitle: "Maywood",
                  //         slug: "https://order.eggnbird.com/menu/egg-n-bird-maywood/",
                  //         internal: false,
                  //       },
                  //       {
                  //         pageTitle: "Cypress",
                  //         slug: "https://order.eggnbird.com/menu/egg-n-bird-cypress",
                  //         internal: false,
                  //       },

                  //       {
                  //         pageTitle: "Pico Rivera",
                  //         slug: "https://order.eggnbird.com/menu/egg-n-bird-pico-rivera",
                  //         internal: false,
                  //       },
                  //     ],
                  //   },
                  // },
                ]}
                backgroundColor={"white"}
                bordered={false}
                centerMobileNavItems={true}
                evenSpacing={false}
                fixed={false}
                header={data.businessName}
                linksAs={"h3"}
                logo={data.logo}
                primaryContentPosition={"left"}
                primaryContentCentered={false}
                internalLinkComponent={Link}
              />
            </div>
            <div className="page-container">{children}</div>
            <Footer
              backgroundColor={"primary"}
              horizontalAlign={"center"}
              verticalAlign={"middle"}
              columns={[
                <CopyrightLabel
                  company={data.businessName}
                  phrase={"All Rights Reserved"}
                />,
                <SiteMap
                  links={[
                    { pageTitle: "Home", slug: "/", internal: true },
                    {
                      pageTitle: "Contact Us",
                      slug: "/contact/",
                      internal: true,
                    },
                    {
                      pageTitle: "Our Story",
                      slug: "/our-story/",
                      internal: true,
                    },
                    {
                      pageTitle: "Catering",
                      slug: "https://order.eggnbird.com",
                      internal: false,
                    },
                    {
                      pageTitle: "Gift Cards",
                      slug: "/giftcards/",
                      internal: true,
                    },
                    {
                      pageTitle: "Locations",
                      slug: "/locations/",
                      internal: true,
                    },
                    {
                      pageTitle: "Franchise",
                      slug: "/franchise/",
                      internal: true,
                    },
                    {
                      pageTitle: "Order Online",
                      slug: "https://order.eggnbird.com/",
                      internal: false,
                    },
                    { pageTitle: "Careers", slug: "/careers/", internal: true },
                    {
                      pageTitle: "Privacy Policy",
                      slug: "/privacypolicy/",
                      internal: true,
                    },
                    
                    {
                      pageTitle: "Terms of Use",
                      slug: "https://fisherman.gumlet.io/public/eggandbird/Terms_Of_Use_-_Egg_N_Bird.pdf",
                      internal: false,
                    },
                    // {
                    //   pageTitle: "Privacy Policy",
                    //   slug: "https://fisherman.gumlet.io/public/eggandbird/DATA_PRIVACY_NOTICE_-_Egg_N_Bird.pdf",
                    //   internal: false,
                    // }
                  ]}
                  horizontal={true}
                  bulleted={false}
                  internalLinkComponent={Link}
                />,
                <SocialMedia
                  socialMediaValues={data.socialMedia}
                  buttonType={"circle"}
                  centerHeader={false}
                  colors={"secondary"}
                  groupButtons={false}
                  groupVertically={false}
                  header={""}
                  inverted={false}
                  labelPosition={"right"}
                  showLabels={false}
                />,
              ]}
            />
          </div>
        );
      }}
    />
  );
};
